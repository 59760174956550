// extracted by mini-css-extract-plugin
export var tileContent = "s_sM d_w d_H d_Z";
export var teamTextLeft = "s_sN d_dv";
export var teamTextCenter = "s_sP d_dw";
export var teamTextRight = "s_sQ d_dx";
export var alignLeft = "s_qj d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "s_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "s_qm d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "s_sR d_dW";
export var teamContainerFull = "s_sS d_dT";
export var teamRowWrapper = "s_sT d_cc";
export var teamTileWrapper = "s_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "s_sV d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "s_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "s_j5 d_j5 d_Z";
export var teamHoverNoGutters = "s_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "s_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "s_sW d_j7";
export var teamInfoWrapperRound = "s_j7 d_j7";
export var teamInfoWrapper = "s_sX d_j8 d_0";
export var teamInfoWrapperNoGutters = "s_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "s_jZ d_jZ";
export var teamImgWrapperAlt = "s_j0 d_j0";
export var teamImgWrapperNoGutters = "s_kb d_kb";
export var teamHeader = "s_sY d_cw";
export var teamHeaderAlt = "s_sZ d_cw";
export var teamHeaderNoGutters = "s_s0 d_cw d_cD";