// extracted by mini-css-extract-plugin
export var alignLeft = "t_qj d_fp d_bG d_dv";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignRight = "t_qm d_fr d_bH d_dx";
export var menuContainer = "t_s1 d_dW";
export var menuContainerFull = "t_s2 d_dT";
export var menuMainHeader = "t_jC d_jC d_w d_c3";
export var menuComponentWrapper = "t_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "t_jH d_jH d_cy";
export var menuComponentText = "t_jD d_jD d_cn";
export var menuComponentTextDesign2 = "t_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "t_jJ d_jJ d_w d_Z";