// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "v_s3 d_gS d_cw d_dv";
export var quoteParagraphCenter = "v_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "v_gV d_gV d_cw d_dx";
export var quoteRowLeft = "v_s4 d_bG";
export var quoteRowCenter = "v_s5 d_bD";
export var quoteRowRight = "v_s6 d_bH";
export var quoteWrapper = "v_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "v_gR d_gR";
export var quoteExceptionSmall = "v_rW q_rW";
export var quoteExceptionNormal = "v_rX q_rX";
export var quoteExceptionLarge = "v_rY q_rY";
export var quoteAuthorExceptionSmall = "v_rZ q_rZ";
export var quoteAuthorExceptionNormal = "v_r0 q_r0";
export var quoteAuthorExceptionLarge = "v_r1 q_r1";